import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { LoadingBarService } from 'src/app/components/loading-bar/loading-bar.service';

export const loadingInterceptor: HttpInterceptorFn = (req, next) => {
  const loadingBarService = inject(LoadingBarService);

  // Set the loading status to true
  loadingBarService._setLoadingStatus(true, req.url);

  return next(req).pipe(
    finalize(() => {
      // Set the status to false if there are any errors or the request is completed
      loadingBarService._setLoadingStatus(false, req.url);
    })
  );
};
