import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SeoService } from './core/seo/seo.service';
import { I18nService } from './core/translation/i18n.service';
import { environment } from 'src/environments/environment';
import { Logger } from './core/logger/logger';
import { IconsService } from './core/icons/icons.service';
import { ConfigService } from './core/config/config.service';
import { SplashScreenService } from './core/splash-screen/splash-screen.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private _splashScreenService: SplashScreenService,
    private _iconsService: IconsService,
    private _seoService: SeoService,
    private _i18nService: I18nService
  ) {}

  ngOnInit(): void {
    // Enable logger production mode if the app is product mode
    if (environment.production) {
      Logger.enableProductionMode();
    }

    // Initializes the seo service
    this._seoService.init();

    // Initializes the i18n service
    this._i18nService.init(
      environment.defaultLanguage,
      environment.supportedLanguages
    );
  }

  ngOnDestroy() {
    // Unsubscribe the seo service
    this._seoService.destroy();

    // Unsubscribe the i18n service
    this._i18nService.destroy();
  }
}
