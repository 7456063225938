import { HttpInterceptorFn } from '@angular/common/http';
import { AuthUtils } from './auth.utils';
import { inject } from '@angular/core';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ApiEndpoint } from '../http/api';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  // Skip if it's the request for getting new access token from refresh token
  if (req.url.includes(ApiEndpoint.RefreshToken)) {
    return next(req);
  }
  // Inject the authService
  const authService = inject(AuthService);

  // Clone the request object
  let newReq = req.clone();

  // Check if the request url is the server url
  const isServerUrl = req.url.startsWith(environment.serverUrl);

  // If the access token expired, get the new one
  if (
    authService.accessToken &&
    AuthUtils.isTokenExpired(authService.accessToken) &&
    isServerUrl
  ) {
    return authService.getAccessToken().pipe(
      switchMap((res) => {
        newReq = req.clone({
          headers: req.headers.set(
            'Authorization',
            'Bearer ' + authService.accessToken
          ),
        });
        return next(newReq);
      }),
      catchError((err) => {
        authService.logout();
        return next(newReq);
      })
    );
  }

  // If the access token didn't expire, add the Authorization header.
  if (
    authService.accessToken &&
    !AuthUtils.isTokenExpired(authService.accessToken) &&
    isServerUrl
  ) {
    newReq = req.clone({
      headers: req.headers.set(
        'Authorization',
        'Bearer ' + authService.accessToken
      ),
    });
  }

  return next(newReq);
};
