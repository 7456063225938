import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { inject } from '@angular/core';
import { Logger } from '../../logger/logger';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

const log = new Logger('NoAuthGuard');

export const noAuthGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.check().pipe(
    switchMap((authenticated) => {
      // If the user is already authenticated
      if (authenticated) {
        log.debug('Already authenticated, redirecting...');

        // Navigate to settings
        router.navigateByUrl('/settings');

        // Prevent the access
        return of(false);
      }

      // Allow the access
      return of(true);
    })
  );
};
