<alert
  class="mt-2"
  [style.display]="state.value === 'inactive' ? 'none' : ''"
  [type]="type"
  [appearance]="appearance"
  [dismissible]="dismissable"
  [showIcon]="showIcon"
  (dismissedChanged)="dismissedChanged($event)"
>
  <div
    alertTitle
    *ngIf="title"
    [class]="options.titleClass"
    [attr.aria-label]="title"
  >
    {{ title }}
  </div>
  <div
    *ngIf="message && options.enableHtml"
    role="alert"
    aria-live="polite"
    [class]="options.messageClass"
    [innerHTML]="message"
  ></div>
  <div
    *ngIf="message && !options.enableHtml"
    role="alert"
    aria-live="polite"
    [class]="options.messageClass"
    [attr.aria-label]="message"
  >
    {{ message }}
  </div>
</alert>
