import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from '../auth.service';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Logger } from '../../logger/logger';

const log = new Logger('AuthGuard');

export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);

  return authService.check().pipe(
    switchMap((authenticated) => {
      // If the user is not authenticated...
      if (!authenticated) {
        log.debug('Not authenticated, redirecting...');

        // Logout
        authService.logout();

        return of(false)
      }

      // Allow the access
      return of(true);
    })
  );
};
