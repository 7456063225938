import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ScrollResetDirective } from 'src/app/directives/scroll-reset/scroll-reset.directive';

@Component({
  selector: 'blank-layout',
  standalone: true,
  imports: [ScrollResetDirective, RouterOutlet, NgIf],
  templateUrl: './blank.component.html',
  styleUrls: ['./blank.component.scss'],
})
export class BlankLayoutComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
