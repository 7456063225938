import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterModule,
} from '@angular/router';
import { combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { animations } from 'src/app/core/animations';
import { AuthService } from 'src/app/core/auth/auth.service';
import { ApiEndpoint, ApiMethod } from 'src/app/core/http/api';
import { HttpService } from 'src/app/core/http/services/http.service';
import { NavigationItem } from 'src/app/core/navigation/navigation.model';
import { mobileNavigation } from '../../../core/navigation/navigation';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'sidebar',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    MatIconModule,
    MatTooltipModule,
    RouterModule,
    NgClass,
    TranslateModule,
  ],
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: animations,
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Input() navigation!: NavigationItem[];

  constructor(
    private _authService: AuthService,
    private _activatedRoute: ActivatedRoute,
    private _httpService: HttpService,
    private _router: Router
  ) {
    _router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.onClearStorage(_router.url);
      }
    });
  }
  stores: any[] = [];
  selectedStore: any = null;
  showStores: boolean = false;
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();
  isShowMenu!: boolean;
  mobileNav = mobileNavigation;
  ngOnInit(): void {
    // combineLatest([this._activatedRoute.queryParams, this.getStores()])
    //   .pipe(takeUntil(this._unsubscribeAll))
    //   .subscribe(([queryParams, stores]) => {
    //     const storeId = queryParams['storeId'];
    //     if (!storeId) {
    //       this.selectedStore = this.stores[0];
    //       this._router.navigate([this._router.url], {
    //         queryParams: { storeId: this.selectedStore.id },
    //         queryParamsHandling: 'merge',
    //       });
    //     } else {
    //       this.selectedStore = stores.find((store) => store.id == storeId);
    //     }
    //   });
  }

  ngOnDestroy() {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  getStores() {
    return this._httpService
      .request({
        apiUrl: ApiEndpoint.Store,
        method: ApiMethod.Get,
        params: {
          pageNumber: 1,
          pageSize: 999,
        },
      })
      .pipe(
        map((res) => {
          this.stores = res.content.items || [];
          return this.stores;
        })
      );
  }

  onSelectStore(store: any) {
    this.selectedStore = store;
    this._router.navigate([], {
      queryParams: { storeId: this.selectedStore.id },
    });
    this.showStores = false;
  }

  onClearStorage(url: string) {
    if (!url.includes('inventory')) {
      sessionStorage.removeItem('inventoryParams');
    }
  }
}
