import { NavigationItem } from './navigation.model';

export const defaultNavigation: NavigationItem[] = [
  // {
  //   id: 'group-1',
  //   title: 'Group 1',
  //   type: 'group',
  //   children: [
  //     {
  //       id: 'dashboard',
  //       title: 'Tổng quan',
  //       type: 'basic',
  //       icon: 'design:dashboard',
  //       link: '/dashboard',
  //       disabled: true
  //     },
  //   ],
  // },

  {
    id: 'group-2',
    title: 'Group 1',
    type: 'group',
    children: [
      {
        id: 'sale',
        title: 'Sale.Title',
        type: 'basic',
        icon: 'design:cart',
        link: '/sale',
      },
      // {
      //   id: 'inventory',
      //   title: 'Kho',
      //   type: 'basic',
      //   icon: 'design:store',
      //   link: '/inventory',

      // },
      // {
      //   id: 'shipping',
      //   title: 'Giao nhận',
      //   type: 'basic',
      //   icon: 'design:shipping',
      //   link: '/shipping',
      //   disabled: false
      // },
      // {
      //   id: 'accountant',
      //   title: 'Kế toán',
      //   type: 'basic',
      //   icon: 'design:calculate',
      //   link: '/accountant',
      // },
      {
        id: 'guest',
        title: 'Guest.Title',
        type: 'basic',
        icon: 'design:customer',
        link: '/guest',
      },
    ],
  },

  {
    id: 'group-3',
    title: 'Group 3',
    type: 'group',
    classes: {
      wrapper: 'mt-auto',
    },
    children: [
      // {
      //   id: 'task',
      //   title: 'Công việc',
      //   type: 'basic',
      //   icon: 'design:assignment',
      //   link: '/task',
      // },
      // {
      //   id: 'report',
      //   title: 'Báo cáo',
      //   type: 'basic',
      //   icon: 'design:report',
      //   link: '/report',
      //   disabled: true
      // },
      {
        id: 'setting',
        title: 'Settings.Title',
        type: 'basic',
        icon: 'design:setting',
        link: '/settings',
      },
    ],
  },
];

export const mobileNavigation: NavigationItem[] = [
  // {
  //   id: 'dashboard',
  //   title: 'Tổng quan',
  //   type: 'basic',
  //   icon: 'design:dashboard',
  //   link: '/dashboard',
  //   disabled: true
  // },
  // {
  //   id: 'client',
  //   title: 'Khách hàng',
  //   type: 'basic',
  //   icon: 'design:customer',
  //   link: '/client',
  // },
  // {
  //   id: 'task',
  //   title: 'Công việc',
  //   type: 'basic',
  //   icon: 'design:assignment',
  //   link: '/task',
  // },
  // {
  //   id: 'report',
  //   title: 'Báo cáo',
  //   type: 'basic',
  //   icon: 'design:report',
  //   link: '/report',
  //   disabled: true
  // },
  // {
  //   title: 'Nhân viên',
  //   type: 'basic',
  //   icon: 'design:employee',
  //   link: '/staff',
  // },
  // {
  //   title: 'Sản phẩm',
  //   type: 'basic',
  //   icon: 'design:product',
  //   link: '/product',
  // },
  // {
  //   title: 'Giá bán',
  //   type: 'basic',
  //   icon: 'design:price',
  //   link: '/settings/price',
  // },
  // {
  //   title: 'KPI',
  //   type: 'basic',
  //   icon: 'design:kpi',
  //   link: '',
  //   disabled: true,
  // },
  // {
  //   title: 'Cài đặt',
  //   type: 'basic',
  //   icon: 'design:setup',
  //   link: '/settings/setup',
  // },
  // {
  //   title: 'Cửa hàng',
  //   type: 'basic',
  //   icon: 'design:store',
  //   link: '/store',
  // },
  // {
  //   title: 'Hệ thống',
  //   type: 'basic',
  //   icon: 'design:setup',
  //   link: '/system',
  // },
  // {
  //   title: 'Danh mục',
  //   type: 'basic',
  //   icon: 'design:category',
  //   link: '/settings/categories',
  // },
  // {
  //   title: 'Thông tin tài khoản',
  //   type: 'basic',
  //   icon: 'design:employee',
  //   link: '/profile',
  // },
];
