<div
  class="w-full sm:w-20 hidden sm:flex flex-row sm:flex-col h-full py-4 items-center bg-grey-800 sm:overflow-hidden"
>
  <!-- Navigation -->
  <div class="flex-grow w-full overflow-auto">
    <div class="h-full flex flex-row sm:flex-col px-2">
      <div
        *ngFor="let item of navigation"
        [ngClass]="item.classes?.wrapper"
        class="mb-2 flex flex-col bg-grey-600 overflow-hidden {{
          item.children!.length >= 2 ? 'rounded-md' : 'rounded-lg'
        }}"
      >
        <div
          *ngFor="let child of item.children; let i = index"
          class="relative flex items-center flex-col justify-center hover:bg-grey-500 transition"
          [ngClass]="!child.disabled ? 'cursor-pointer' : '!cursor-not-allowed'"
        >
          <a
            [ngClass]="
              !child.disabled
                ? 'pointer-events-auto opacity-100'
                : 'pointer-events-none opacity-60'
            "
            [routerLink]="child.link"
            class="text-white flex justify-center items-center px-2 py-4 cursor-pointer"
            [matTooltip]="child.title ?? '' | translate"
            [matTooltipPosition]="'left'"
            [queryParamsHandling]="'preserve'"
          >
            <mat-icon
              [routerLinkActive]="'text-warn'"
              [svgIcon]="child.icon ?? ''"
            ></mat-icon>
          </a>
        </div>
      </div>
    </div>
  </div>

  <!-- Footer -->
  <div class="mt-auto px-2 pt-3">
    <a
      class="relative flex-shrink-0 cursor-pointer ring-2 ring-warn hover:ring-[3px] w-14 h-14 rounded-full overflow-hidden block"
      routerLink="/profile"
      [queryParamsHandling]="'preserve'"
    >
      <img src="assets/images/user.png" alt="" class="w-full h-full" />
    </a>
  </div>
</div>

<!--mobile-->
<div
  class="w-full sm:w-20 sm:hidden flex flex-row sm:flex-col h-full py-3 items-center bg-grey-800 sm:overflow-hidden"
>
  <!-- Navigation -->
  <div class="flex-grow w-full overflow-auto">
    <div class="h-full justify-between flex flex-row sm:flex-col px-3">
      <div
        *ngFor="let item of navigation"
        class="flex justify-around"
        [ngClass]="item.id == 'group-2' ? 'w-full gap-x-3' : ''"
      >
        <ng-container *ngIf="item.id == 'group-2'">
          <div
            *ngFor="let child of item.children; let i = index"
            class="relative flex items-center flex-col justify-center bg-grey-600 rounded-md h-full max-w-[50px] max-h-[50px] {{
              child.id !== 'client' ? 'flex-1' : 'hidden'
            }}"
          >
            <ng-container *ngIf="child.id !== 'client'">
              <a
                [ngClass]="
                  !child.disabled
                    ? 'pointer-events-auto opacity-100'
                    : 'pointer-events-none opacity-60'
                "
                [routerLink]="child.link"
                class="text-white flex justify-center items-center p-2 cursor-pointer"
                [routerLinkActive]="'!text-warn'"
                [matTooltip]="child.title ?? '' | translate"
                [matTooltipPosition]="'left'"
                [queryParamsHandling]="'preserve'"
              >
                <mat-icon
                  [svgIcon]="child.icon ?? ''"
                  class="icon-size-4"
                ></mat-icon>
              </a>
            </ng-container>
          </div>
          <div
            class="relative flex items-center flex-col justify-center bg-grey-600 rounded-md flex-1 max-w-[50px] max-h-[50px]"
          >
            <a
              (click)="isShowMenu = true"
              class="text-white flex justify-center items-center p-4 cursor-pointer"
            >
              <mat-icon
                [svgIcon]="'design:dot_horizontal'"
                class="icon-size-4"
              ></mat-icon>
            </a>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
