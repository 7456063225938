<div class="relative flex w-full h-full flex-col sm:flex-row">
  <div class="flex flex-auto w-full flex-col overflow-hidden">
    <div class="flex-grow overflow-auto">
      <div class="mx-auto h-full">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <sidebar [navigation]="navigation"></sidebar>
</div>
