import { Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { noAuthGuard } from './core/auth/guards/noAuth.guard';
import { authGuard } from './core/auth/guards/auth.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '',
    component: LayoutComponent,
    canLoad: [noAuthGuard],
    canActivate: [noAuthGuard],
    canActivateChild: [noAuthGuard],
    children: [
      {
        path: 'login',
        data: {
          title: 'Đăng nhập',
        },
        loadComponent: () =>
          import('./modules/auth/login/login.component').then(
            (c) => c.LoginComponent
          ),
      },
    ],
  },
  {
    path: '',
    component: LayoutComponent,
    canLoad: [authGuard],
    canActivate: [authGuard],
    canActivateChild: [authGuard],
    children: [
      {
        path: 'profile',
        data: {
          title: 'Profile.Title',
          layout: 'admin',
        },
        loadComponent: () =>
          import('./modules/profile/profile.component').then(
            (m) => m.ProfileComponent
          ),
      },
      {
        path: 'settings',
        data: {
          title: 'Cài đặt',
          layout: 'admin',
        },
        loadChildren: () =>
          import('./modules/settings/settings.routes').then((c) => c.routes),
      },
      {
        path: 'brand',
        data: {
          title: 'Brand.Title',
          layout: 'blank',
        },
        loadComponent: () =>
          import('./modules/categories/brand/brand.component').then(
            (m) => m.BrandComponent
          ),
      },
      {
        path: 'catalog',
        data: {
          title: 'Catalog.Title',
          layout: 'blank',
        },
        loadComponent: () =>
          import('./modules/categories/catalog/catalog.component').then(
            (m) => m.CatalogComponent
          ),
      },
      {
        path: 'distribution',
        data: {
          title: 'Distribution.Title',
          layout: 'blank',
        },
        loadComponent: () =>
          import(
            './modules/categories/distribution/distribution.component'
          ).then((m) => m.DistributionComponent),
      },
      {
        path: 'madeInCountry',
        data: {
          title: 'MadeInCountry.Title',
          layout: 'blank',
        },
        loadComponent: () =>
          import(
            './modules/categories/made-in-country/made-in-country.component'
          ).then((m) => m.MadeInCountryComponent),
      },
      {
        path: 'post',
        data: {
          title: 'Post.Post',
          layout: 'blank',
        },
        loadComponent: () =>
          import('./modules/categories/post/post.component').then(
            (m) => m.PostComponent
          ),
      },
      {
        path: 'productAge',
        data: {
          title: 'ProductAge.Title',
          layout: 'blank',
        },
        loadComponent: () =>
          import('./modules/categories/product-age/product-age.component').then(
            (m) => m.ProductAgeComponent
          ),
      },
      {
        path: 'category',
        data: {
          title: 'Category.Title',
          layout: 'blank',
        },
        loadComponent: () =>
          import('./modules/categories/category/category.component').then(
            (m) => m.CategoryComponent
          ),
      },
      {
        path: 'language',
        data: {
          title: 'Language.Title',
          layout: 'blank',
        },
        loadComponent: () =>
          import('./modules/categories/language/language.component').then(
            (m) => m.LanguageComponent
          ),
      },
      {
        path: 'slug',
        data: {
          title: 'Slug.Title',
          layout: 'blank',
        },
        loadComponent: () =>
          import('./modules/categories/slug/slug.component').then(
            (m) => m.SlugComponent
          ),
      },
      {
        path: 'product',
        loadChildren: () =>
          import('./modules/product/product.routes').then((m) => m.routes),
      },
      {
        path: 'unit',
        data: {
          title: 'Unit.Title',
          layout: 'blank',
        },
        loadComponent: () =>
          import('./modules/categories/unit/unit.component').then(
            (m) => m.UnitComponent
          ),
      },
      {
        path: 'businessType',
        data: {
          title: 'BusinessType.Title',
          layout: 'blank',
        },
        loadComponent: () =>
          import(
            './modules/categories/business-type/business-type.component'
          ).then((m) => m.BusinessTypeComponent),
      },
      {
        path: 'pointOfSale',
        data: {
          title: 'PointOfSale.Title',
          layout: 'blank',
        },
        loadComponent: () =>
          import(
            './modules/categories/point-of-sale/point-of-sale.component'
          ).then((m) => m.PointOfSaleComponent),
      },
      {
        path: 'productType',
        data: {
          title: 'ProductType.Title',
          layout: 'blank',
        },
        loadComponent: () =>
          import(
            './modules/categories/product-type/product-type.component'
          ).then((m) => m.ProductTypeComponent),
      },
      {
        path: 'sale',
        data: {
          title: 'Sale.Title',
          layout: 'admin',
        },
        loadComponent: () =>
          import('./modules/sale/sale.component').then((m) => m.SaleComponent),
      },
      {
        path: 'purchase-order',
        data: {
          title: 'PurchaseOrder.Title',
          layout: 'blank',
        },
        loadComponent: () =>
          import('./modules/purchase-order/purchase-order.component').then(
            (m) => m.PurchaseOrderComponent
          ),
      },
      {
        path: 'purchase-order/:id',
        data: {
          title: 'PurchaseOrder.PurchaseOrderDetail',
          layout: 'blank',
        },
        loadComponent: () =>
          import(
            './modules/purchase-order/purchase-order-detail/purchase-order-detail.component'
          ).then((m) => m.PurchaseOrderDetailComponent),
      },
      {
        path: 'guest',
        data: {
          title: 'Guest.Title',
          layout: 'admin',
        },
        loadComponent: () =>
          import('./modules/guest/guest.component').then(
            (m) => m.GuestComponent
          ),
      },
      {
        path: 'guest/:id',
        data: {
          title: 'Guest.GuestDetail',
          layout: 'blank',
        },
        loadComponent: () =>
          import('./modules/guest/guest-detail/guest-detail.component').then(
            (m) => m.GuestDetailComponent
          ),
      },
      {
        path: 'user',
        data: {
          title: 'User.Title',
          layout: 'blank',
        },
        loadComponent: () =>
          import('./modules/categories/user/user.component').then(
            (m) => m.UserComponent
          ),
      },
    ],
  },
];
